/* eslint-disable prettier/prettier */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import { i18n } from "@/plugins/i18n";
import SettingsService from "./services/settings.service";
import ApiService from "./services/api.service";
import AuthService from "./services/auth.service";
import rules from "./plugins/validation";
import helpers from "./plugins/helpers";
import timerWorker from "./plugins/worker-timer";
import VueJWT from "vuejs-jwt";
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";
import VueTheMask from "vue-the-mask";
import money from "v-money";
import "./plugins/vuetify-money.js";
import VueGeolocation from "vue-browser-geolocation";
import * as VueGoogleMaps from "vue2-google-maps";
import VueCookies from "vue-cookies";
import VueSignaturePad from "vue-signature-pad";

Vue.config.productionTip = false;
Vue.use(VueSignaturePad);

SettingsService.init().then(async () => {
  const parsed = await import("./translations.csv");
  i18n.setLocaleMessage(
    "en",
    Object.keys(parsed)
      .map((i) => ({ [parsed[i][0]]: parsed[i][1] }))
      .reduce(function (result, current) {
        return Object.assign(result, current);
      }, {})
  );
  i18n.setLocaleMessage(
    "fr",
    Object.keys(parsed)
      .map((i) => ({ [parsed[i][0]]: parsed[i][2] }))
      .reduce(function (result, current) {
        return Object.assign(result, current);
      }, {})
  );

  var settings = SettingsService.settings;

  // Set the base URL of the API
  ApiService.init(settings.APIURL);
  AuthService.init(settings);
  store.dispatch("common/setSettings", settings);
  await store.dispatch("common/loadPortalSettings");

  Vue.use(rules);
  Vue.use(helpers);
  Vue.use(timerWorker);
  Vue.use(VueTheMask);

  Vue.use(money, { precision: 4 });
  Vue.use(VueCookies);

  Vue.use(VuetifyGoogleAutocomplete, {
    // apiKey: settings.GoogleAPIKEY,
  });

  Vue.use(VueGeolocation);
  Vue.use(VueGoogleMaps, {
    load: {
      key: settings.GoogleAPIKEY,
      libraries: "places",
    },
  });

  Vue.filter("toCurrency", function (value) {
    if (typeof value !== "number") {
      return value;
    }
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  });

  const jwtOptions = {
    signKey: settings.RegistrationTokenSecret,
  };
  Vue.use(VueJWT, jwtOptions);

  new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
