const state = {
  drawer: false,
};

const actions = {
  toggleDrawer(context) {
    context.commit("toggleDrawer");
  },
  updateDrawer(context, value) {
    context.commit("updateDrawer", value);
  },
};
const getters = {};

const mutations = {
  openDrawer(state) {
    state.drawer = true;
  },
  closeDrawer(state) {
    state.drawer = false;
  },
  toggleDrawer(state) {
    state.drawer = !state.drawer;
  },
  updateDrawer(state, value) {
    state.drawer = value;
  },
};

export const navbar = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
