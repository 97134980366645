<template>
  <v-container class="px-0 pt-1">
    <v-row no-gutters>
      <v-col cols="12" md="4" class="pa-3">
        <MyInfoDashboardItemSba :height="3" />
      </v-col>
      <v-col cols="12" md="4" class="pa-3">
        <MemberBalanceItem :balance="userProfile.Balance" />
      </v-col>
      <v-col cols="12" md="4" class="pa-3">
        <JobsDashboardItem :height="2" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MyInfoDashboardItemSba from "./dashboardItems/MyInfoDashboardItemSba.vue";
import JobsDashboardItem from "./dashboardItems/JobsDashboardItem.vue";
import MemberBalanceItem from "./dashboardItems/MemberBalanceDashboardItem.vue";

export default {
  components: {
    JobsDashboardItem,
    MyInfoDashboardItemSba,
    MemberBalanceItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    sending: Boolean,
  },
  data() {
    return {
      search: null,
      isSearchFound: false,
      triggerSearch: false,
      pdfSource: null,
      pdfTitle: null,
      showPdf: false,
      isUserAllowedToLogin: false,
      allowedAccountStatuses: [
        "active",
        "deferred",
        "terminated",
        "paid",
        "retired",
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["role", "user"]),
    ...mapGetters("data", ["memberHasHSA"]),
    ...mapGetters("common", [
      "isPensionsPortal",
      "isUnionPortal",
      "isDemoMockPlan",
      "useLegacyClaims",
      "userProfile",
      "hideFrequentlyUsed",
    ]),
    numberOfWidgetsInARow() {
      return this.$vuetify.breakpoint.mdAndUp
        ? 3
        : this.$vuetify.breakpoint.xsOnly
        ? 1
        : 2;
    },
    loadingUserProfile() {
      return (
        this.userProfile === null ||
        this.userProfile === undefined ||
        this.userProfile === {}
      );
    },
    itemsToShow() {
      let toShow = this.items.filter((i) => {
        if (i.ShowTo) {
          if (this.isPensionsPortal) {
            if (this.loadingUserProfile) {
              return i.ShowTo.includes("Loading");
            }
            if (
              this.userProfile?.AccountStatus !== "Active" &&
              this.userProfile?.AccountStatus !== "Deferred" &&
              this.userProfile?.AccountStatus !== "Terminated" &&
              i.Name === "MyLifeEventsDashboardItem"
            ) {
              return true;
            }

            return (
              i.ShowTo.includes(this.userProfile?.AccountStatus) ||
              i.ShowTo.includes("All")
            );
          }
          return i.ShowTo.includes(this.role);
        } else return true;
      });

      const usedBenefitsWidgetIndex = toShow.findIndex(
        (w) => w.Name === "UsedBenefitsDashboardItemSba"
      );

      if (this.useLegacyClaims && usedBenefitsWidgetIndex !== -1) {
        toShow[usedBenefitsWidgetIndex].Name =
          "UsedBenefitsDashboardItemSbaLegacy";
      }

      if (usedBenefitsWidgetIndex !== -1 && this.hideFrequentlyUsed) {
        toShow = toShow.filter(
          (i) =>
            i.Name !== "UsedBenefitsDashboardItemSba" &&
            i.Name !== "UsedBenefitsDashboardItemSbaLegacy"
        );
      }

      console.log("hideFrequentlyUsed: ", this.hideFrequentlyUsed);

      const documentsWidgetIndex = toShow.findIndex(
        (w) => w.Name === "DocumentsDashboardItemSba"
      );

      const hsaWidgetIndex = toShow.findIndex(
        (w) => w.Name === "HSADashboardItemSba"
      );

      if (this.memberHasHSA) {
        if (hsaWidgetIndex !== -1 && documentsWidgetIndex !== -1) {
          if (this.hideFrequentlyUsed) {
            toShow[hsaWidgetIndex].Size = 4;
            toShow[documentsWidgetIndex].Size = 5;
          } else {
            toShow[usedBenefitsWidgetIndex].Size = 4;
          }
        }
      } else {
        toShow = toShow.filter((i) => i.Name !== "HSADashboardItemSba");
        if (this.hideFrequentlyUsed) {
          const documentsWidgetIndex = toShow.findIndex(
            (w) => w.Name === "DocumentsDashboardItemSba"
          );
          toShow[documentsWidgetIndex].Size = 9;
        }
      }
      return toShow;
    },
  },
  watch: {
    userProfile: {
      handler(v) {
        if (v) {
          // Check if member is Neospin member and the member status is not in the allowed list
          if (this.isPensionsPortal && this.user?.neospinMemberId) {
            if (
              !this.allowedAccountStatuses.includes(
                this.userProfile?.AccountStatus?.toLowerCase()
              )
            ) {
              console.log(
                "user is not allowed to access this portal",
                this.user?.neospinMemberId,
                "AccountStatus: ",
                this.userProfile?.AccountStatus
              );
              this.isUserAllowedToLogin = false;
              this.$store.dispatch("auth/logout");
            } else {
              this.isUserAllowedToLogin = true;
            }
          } else {
            this.isUserAllowedToLogin = true;
          }
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    if (this.isUnionPortal) {
      return;
    }

    if (!this.isPensionsPortal) {
      var result = await this.getHSA({
        planIdentityId: this.user.plan,
        memberIdentityId: this.user.sub,
      });
      await this.getHasBeneficiariesFlag();
      if (this.isDemoMockPlan) {
        this.setMockHSA(result?.data?.value);
      }
    }
  },
  methods: {
    ...mapActions("data", ["getHSA", "setMockHSA"]),
    ...mapActions("common", ["getHasBeneficiariesFlag"]),
    openPdf(item) {
      this.pdfSource =
        item["FileUrl" + this.$vuetify.lang.current.toUpperCase()];
      this.pdfTitle = item["Title" + this.$vuetify.lang.current.toUpperCase()];
      this.showPdf = true;
      this.search = null;
    },
    closePdfViewer() {
      this.showPdf = false;
      this.pdfSource = null;
    },
    updateOrder(itemInPositionOrder, item) {
      const newOrder = parseInt(itemInPositionOrder);

      if (isNaN(newOrder)) {
        return;
      }
      if (newOrder === item.Order) {
        return;
      }

      if (
        item.Height === 2 &&
        itemInPositionOrder >= this.items.length - this.numberOfWidgetsInARow
      ) {
        return;
      }

      const itemInPosition = this.items.find(
        (i) => i.Order === itemInPositionOrder
      );
      if (itemInPosition.Name === "EmptyDashboardItem") return;

      if (
        item.Order >= this.items.length - this.numberOfWidgetsInARow &&
        itemInPosition.Height === 2
      )
        return;
      itemInPosition.Order = item.Order;

      item.Order = newOrder;
      if (item.Height === 2) {
        const emptyWidget = this.items.find(
          (i) => i && i.Name === "EmptyDashboardItem"
        );
        const itemInPosition = newOrder + this.numberOfWidgetsInARow - 1;
        this.updateOrder(itemInPosition, emptyWidget);
      }

      if (itemInPosition.Height === 2) {
        const emptyWidget = this.items.find(
          (i) => i && i.Name === "EmptyDashboardItem"
        );
        const itemUnderTallWidget =
          itemInPosition.Order + this.numberOfWidgetsInARow - 1;
        this.updateOrder(itemUnderTallWidget, emptyWidget);
      }
    },

    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("id", item.Id);
      evt.dataTransfer.setData("x", evt.x);
      evt.dataTransfer.setData("y", evt.y);
      console.log("drag: ", evt, item);
    },

    onDrop(evt) {
      const id = evt.dataTransfer.getData("id");
      const item = this.items.find((item) => item.Id == id);
      const itemInPosition = this.getItemInPosition(evt.x, evt.y);
      console.log("dropped: ", evt, id, item, itemInPosition);

      if (
        item.Name === "AddWidgetDashboardItem" ||
        item.Name === "EmptyDashboardItem"
      )
        return;

      if (itemInPosition !== undefined) {
        const itemInPositionOrder = parseInt(itemInPosition.id.split("-")[1]);
        this.updateOrder(itemInPositionOrder, item);
      }
    },
    getItemInPosition(x, y) {
      let els = document.elementsFromPoint(x, y);
      console.log(els);
      let materialCardElements = els.filter((o) =>
        o.classList.contains("material-card")
      );
      if (materialCardElements.length === 1) {
        return materialCardElements[0];
      }
      return undefined;
    },
    checkRole(item) {
      if (item.ShowTo) {
        if (this.isPensionsPortal) {
          if (this.loadingUserProfile) {
            return item.ShowTo.includes("Loading");
          }
          if (item.ShowTo.includes(this.userProfile?.AccountStatus))
            return true;
          if (item.ShowTo.includes("All")) return true;
        }
        if (
          this.userProfile?.AccountStatus !== "Active" &&
          this.userProfile?.AccountStatus !== "Defferred" &&
          this.userProfile?.AccountStatus !== "Terminated" &&
          !this.loadingUserProfile &&
          item.Name === "MyLifeEventsDashboardItem"
        ) {
          return true;
        }
        return item.ShowTo.includes(this.role);
      } else return true;
    },
  },
};
</script>
<style scoped>
.search {
  max-width: 1200px;
  margin: 20px auto auto;
}
.search >>> .v-input__slot {
  background-color: white !important;
  border-radius: 10px;
}
.search >>> fieldset {
  border: 2px solid rgb(189, 189, 189);
  border-radius: 10px;
}

@media screen and (max-width: 375px) {
  .search >>> .v-label {
    font-size: 14px;
  }
}
</style>
<style lang="scss" scoped>
.pdf-preview {
  position: fixed;
  width: 65%;
  min-width: 600px;
  height: 80vh;
  top: 100px;
  z-index: 9999999;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
  box-shadow: 0px 0px 10px #00000048;

  button {
    position: absolute;
    top: -20px;
    right: -15px;
    width: 35px;
    height: 35px;
    background: #555;
    border: 0;
    box-shadow: 0px 0px 10px #00000048;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
  }

  iframe {
    border: 0;
  }
}

.transition-anim-enter-active,
.transition-anim-leave-active {
  transition: opacity 0.3s ease-in;
}

.transition-anim-enter,
.transition-anim-leave-to {
  opacity: 0;
}
</style>
