<template>
  <div>
    <v-dialog v-model="show" max-width="500">
      <v-card>
        <v-card-title>Apply For Job</v-card-title>
        <v-card-text>
          <v-form
            v-model="isFormValid"
            id="applyJob"
            ref="form"
            @submit.prevent="sendApplyForJob"
          >
            <v-row>
              <v-col>
                <v-menu
                  v-model="menuStart"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      prepend-icon="mdi-calendar"
                      label="Start Date"
                      v-model="startDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      required
                      :rules="formRules"
                      class="required"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    @input="menuStart = false"
                    required
                    :rules="formRules"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="menuEnd"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      prepend-icon="mdi-calendar"
                      label="End Date"
                      v-model="endDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      required
                      :rules="[requiredRule, expiryRule]"
                      class="required"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    @input="menuEnd = false"
                    required
                    :rules="[requiredRule, expiryRule]"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Comments" v-model="comments">
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <slot v-bind="{ form: $refs.form }">
            <v-spacer></v-spacer>
            <v-btn plain @click="cancelModal()">Cancel</v-btn>
            <v-btn
              color="primary"
              type="submit"
              :disabled="!isFormValid"
              :formId="title"
              form="applyJob"
              v-bind="$attrs"
              v-on="$listeners"
              >Apply</v-btn
            >
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <material-card-sba
      :height="3"
      :order.sync="internalOrder"
      :maxOrderValue="maxOrderValue"
      :widgetId="widgetId"
      widgetIcon="mdi-truck-fast"
      @remove-widget="$emit('remove-widget', $event)"
      @add-widget="$emit('add-widget', $event)"
      :color="`primary`"
      title="Available Jobs"
    >
      <v-card-text
        style="
          display: flex;
          flex-direction: column;
          height: 90%;
          width: 100%;
          overflow: auto;
        "
      >
        <v-list>
          <v-list-item
            v-for="item in items"
            :key="item.id"
            style="text-align: left"
          >
            <v-list-item-avatar>
              <v-avatar color="primary">
                <v-icon color="white">mdi-truck-fast</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.Employer.CompanyName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $helpers.formatDate(item.StartDate) }}<br />
                {{ item.EmployerProject.Name }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="applyForJob(item)" color="primary">Apply</v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-btn
          class="primary"
          @click="$router.push('/jobs')"
          style="
            margin-top: auto;
            margin-bottom: 4rem;
            margin-right: 1rem;
            margin-left: auto;
          "
          >MORE</v-btn
        >
      </v-card-text>
    </material-card-sba>
  </div>
</template>

<script>
import MaterialCardSba from "@/components/VueMaterialDashboard/material/MaterialCardSbaDashboard.vue";
import { mapGetters } from "vuex";
import ApiService from "@/services/api.service";
import SettingsService from "@/services/settings.service";

export default {
  components: { MaterialCardSba },
  props: {
    widgetId: String,
    size: Number,
    height: Number,
    order: Number,
    maxOrderValue: Number,
  },
  async mounted() {
    await this.load();
  },
  computed: {
    ...mapGetters("common", ["union"]),
    formRules() {
      return [(v) => !!v || "Field is required"];
    },
    isValidExpiry() {
      return (
        new Date(this.endDate).getTime() > new Date(this.startDate).getTime()
      );
    },
    requiredRule() {
      return (v) => !!v || "End date is required";
    },
    expiryRule() {
      return () => {
        if (this.isValidExpiry) return true;
        return "End date must be after start date";
      };
    },
  },
  data() {
    return {
      isFormValid: false,
      show: false,
      menuStart: false,
      menuEnd: false,
      internalSize: undefined,
      internalHeight: undefined,
      internalOrder: undefined,
      loading: false,
      headers: [
        { text: "Start Date", value: "StartDate" },
        { text: "Company", value: "Employer.CompanyName" },
        { text: "Project", value: "EmployerProject.Name" },
        { text: "Job Status", value: "Status" },
        { text: "Required", value: "Required" },
        { text: "Filled", value: "Filled" },
        { text: "", value: "actions" },
      ],
      items: [],
      jobId: "",
      startDate: "",
      endDate: "",
      comments: "",
    };
  },
  methods: {
    async load() {
      const url = SettingsService.settings.DISPATCHURL;
      const resp = await ApiService.get(`${url}jobs`);

      this.items = resp.data;
    },
    applyForJob(item) {
      this.jobId = item.Id;
      this.show = true;
    },
    cancelModal() {
      this.show = false;
      this.startDate = "";
      this.endDate = "";
      this.comments = "";
    },
    async sendApplyForJob() {
      const form = this.$refs.form;
      if (!form.validate()) {
        return;
      }
      this.loading = true;
      try {
        const url = SettingsService.settings.DISPATCHURL;

        const applyForJob = {
          jobId: this.jobId,
          startDate: this.startDate,
          endDate: this.endDate,
          comments: this.comments,
        };

        await ApiService.post(`${url}dispatch-member`, applyForJob);

        this.load();
      } finally {
        this.loading = false;
        this.show = false;
        this.startDate = "";
        this.endDate = "";
        this.comments = "";
      }
    },
  },
  watch: {
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
};
</script>
