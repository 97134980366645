<template>
  <div id="home" style="margin-left: 8px; margin-right: 8px">
    <drag-drop-dashboard
      @add-widget="addWidget($event)"
      @remove-widget="removeWidget($event)"
      @send-message="sendMessageToAdmin()"
      :sending="sending"
      v-if="userDashboardItems !== undefined"
      :items="userDashboardItems"
    />
    <div v-else class="align-self-center mt-10">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
    </div>

    <v-snackbar v-model="snackbar" color="success" :timeout="timeout">
      {{ $t("components.vue_material_dashboard.items.contactus.success") }}
      <v-btn dark text @click="snackbar = false">
        {{ $t("globals.close") }}
      </v-btn>
    </v-snackbar>
    <error-handler :error="error"></error-handler>

    <v-dialog v-model="registerDialog" width="1200" persistent class="reg">
      <!-- <registration-completion-component
        :member="internalMember"
        @close-dialog="closeDialog"
      /> -->
      <component
        v-if="registrationCompletionComponent"
        :is="registrationCompletionComponent.Name"
        :member="internalMember"
        @update-dependants="internalMember.Dependants = $event"
        @update-beneficiaries="internalMember.Beneficiaries = $event"
        @close-dialog="closeDialog"
        @update-notifications="$emit('update-notifications', $event)"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ErrorHandler from "@/components/ErrorHandler.vue";
import DragDropDashboard from "@/components/VueMaterialDashboard/DragDropDashboard.vue";
import RegistrationCompletionComponent from "@/components/RegistrationCompletion/RegistrationCompletionComponent.vue";
import RegistrationCompletionComponentSba from "@/components/RegistrationCompletion/RegistrationCompletionComponentSba.vue";

export default {
  components: {
    DragDropDashboard,
    ErrorHandler,
    RegistrationCompletionComponent,
    RegistrationCompletionComponentSba,
  },
  data: () => ({
    userDashboardItems: undefined,
    userHorizontalMenuItems: undefined,
    error: null,
    sending: false,
    snackbar: false,
    registerDialog: false,
    internalMember: undefined,
    lang: null,
    timeout: -1,
    cookie: null,
    currentMembership: null,
  }),
  computed: {
    ...mapGetters("common", [
      "dashboardItems",
      "horizontalMenuItems",
      "language",
      "userProfile",
      "languages",
      "registrationCompletionComponent",
      "identityUser",
      "portalPlanIdentity",
    ]),
    ...mapGetters("auth", ["user"]),
    numberOfWidgetsInARow() {
      return this.$vuetify.breakpoint.mdAndUp
        ? 3
        : this.$vuetify.breakpoint.xsOnly
        ? 1
        : 2;
    },
  },
  watch: {
    userProfile: {
      handler(v) {
        if (v !== null && v !== undefined) {
          let member = JSON.parse(JSON.stringify(this.userProfile));
          if (!member.BankAccount) {
            member.BankAccount = {};
          }
          this.internalMember = member;
        }
      },
      immediate: true,
    },
    dashboardItems: {
      handler(v) {
        if (v !== null && v !== undefined) {
          this.userDashboardItems = v;
        }
      },
      immediate: true,
    },
    horizontalMenuItems: {
      handler(v) {
        if (v !== null && v !== undefined) {
          this.userHorizontalMenuItems = v;
        }
      },
      immediate: true,
    },
    language: {
      handler() {
        this.setPageTitle("");
        this.setPageHelpTooltip(this.$t("views.page.help_tooltip.home"));
      },
      immediate: true,
    },
    cookie: {
      handler(v) {
        if (v) {
          const lang = this.parseCookie(v)?.uic;
          console.log("lang from cookie", lang);
          this.setLang(lang);
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.lang = this.$route.query.lang;
    this.cookie = this.$cookies.get(".AspNetCore.Culture");
    console.log("cookie at home view", this.cookie);
    this.setLang(this.lang);

    localStorage.removeItem("LAST_LOGGEDOUT_MEMBERGROUP");
    if (this.userProfile) {
      this.internalMember = JSON.parse(JSON.stringify(this.userProfile));
    }

    this.setPageTitle("");
    this.setPageHelpTooltip(this.$t("views.page.help_tooltip.home"));
    if (this.dashboardItems !== null && this.dashboardItems !== undefined) {
      this.userDashboardItems = this.dashboardItems;
    }
    if (
      this.horizontalMenuItems !== null &&
      this.horizontalMenuItems !== undefined
    ) {
      this.horizontalMenuItems[0].Info = 2;
      this.userHorizontalMenuItems = this.horizontalMenuItems;
    }
  },
  methods: {
    ...mapActions("common", [
      "setDashboardItems",
      "setBenSource",
      "setPageTitle",
      "setPageHelpTooltip",
      "setLanguage",
      "loadIdentityUser",
    ]),
    ...mapActions("data", ["sendMessage"]),
    addWidget(widget) {
      const nextId = this.userDashboardItems.length;
      const widgetToAdd = {
        Id: nextId.toString(),
        Name: widget.Name,
        Order: this.userDashboardItems.length,
        Size: widget.Size,
        Height: widget.Height,
      };
      this.userDashboardItems.push(widgetToAdd);

      if (widgetToAdd.Height === 2) {
        const emptyWidget = {
          Id: (nextId + this.numberOfWidgetsInARow).toString(),
          Order: nextId + this.numberOfWidgetsInARow,
          Name: "EmptyDashboardItem",
          Size: 8,
        };
        this.userDashboardItems.push(emptyWidget);
      }
      this.setDashboardItems(this.userDashboardItems);
      // TODO Update Member Widgets in database
    },
    removeWidget(widgetId) {
      let widgetToRemove = this.userDashboardItems.find(
        (w) => w.Id === widgetId
      );
      let widgets = this.userDashboardItems.filter((w) => w.Id !== widgetId);
      if (widgetToRemove.Height === 2) {
        widgets = widgets.filter((w) => w.Name !== "EmptyDashboardItem");
      }
      widgets.forEach((w, i) => {
        w.Id = i.toString();
        w.Order = i;
      });
      this.userDashboardItems = widgets;
      this.setDashboardItems(this.userDashboardItems);
      // TODO Update Member Widgets in database
    },
    async sendMessageToAdmin() {
      this.sending = true;
      try {
        await this.sendMessage({
          planIdentityId: this.user.plan,
          memberIdentityId: this.user.sub,
          payload: this.message,
        });
        this.message = undefined;
        this.snackbar = true;
      } catch (error) {
        if (error.customMessage === null || error.customMessage === undefined) {
          this.error = {
            customMessage: this.$t("views.home.send_error"),
          };
        } else {
          this.error = error;
        }
      } finally {
        this.sending = false;
      }
    },
    closeDialog() {
      this.registerDialog = false;
      const collection = document.getElementsByClassName("v-application--wrap");
      collection[0].classList.remove("blur");
    },
    parseCookie(str) {
      if (!str) return;
      str = decodeURIComponent(str);
      return str
        .split("|")
        .map((v) => v.split("="))
        .reduce((acc, v) => {
          acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(
            v[1].trim()
          );
          return acc;
        }, {});
    },
    setLang(lang) {
      if (lang) {
        this.$vuetify.lang.current = lang;
        const locale = this.languages.find((item) => item.language === lang);
        if (locale) {
          this.setLanguage(locale);
        }
      }
    },
  },
};
</script>
<style>
.blur {
  filter: blur(5px);
  -webkit-filter: blur(5px);
}
</style>
